<template>
  <ConfirmDialog
    :value.sync="show"
    :title="`Kody ${humanizedModel}`"
    :maxWidth="model === 'icf' ? '700px' : '900px'"
    positiveButtonText="Zapisz"
    :positiveAction="save"
    :loading="loading"
    dontHideOnPositiveAction
    negativeButtonColor="primary"
    :compact="false"
    showCloseBtn
  >
    <v-layout column slot="content">
      <DefaultLabel class="mx-4">Ulubione:</DefaultLabel>
      <FavoriteMedicalCodeDelegate
        class="mx-4"
        v-for="fav in favorites"
        :key="fav.id"
        :item="fav"
        :open="open"
        :favorites.sync="favorites"
      ></FavoriteMedicalCodeDelegate>
      <v-divider class="mt-4 mx-4"></v-divider>
      <DefaultLabel class="mt-4 mb-1 mx-4">Pełna lista kodów:</DefaultLabel>
      <FavoriteMedicalCodesFilter
        class="mb-2"
        :search.sync="search"
        :favorites.sync="favorites"
        :model="model"
      ></FavoriteMedicalCodesFilter>
      <v-treeview
        class="mr-2 ml-1 default-style"
        v-show="!search"
        :items="codes"
        item-text="text"
        :load-children="fetchChildren"
        selection-type="independent"
        loading-icon="fas fa-spinner"
        open-on-click
        transition
        expand-icon=""
      >
        <template v-slot:label="{ item, open }">
          <FavoriteMedicalCodeDelegate
            :item="item"
            :open="open"
            :favorites.sync="favorites"
            card
          ></FavoriteMedicalCodeDelegate>
        </template>
      </v-treeview>
    </v-layout>
  </ConfirmDialog>
</template>

<script>
import MedicalcodesService from "@/services/medicalcodes.service";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";

export default {
  mixins: [ProxyCRUDMixin],
  props: {
    value: {},
    model: {}
  },
  data() {
    return {
      codes: [],
      open: [],
      favorites: [],
      search: null,
      pagination: {
        rowsPerPage: -1,
        page: 1,
        min: 1,
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    humanizedModel() {
      switch(this.model) {
        case "icf":
          return "ICF";
        case "icd10":
          return "ICD-10";
        case "icd9":
          return "ICD-9";
      }
    }
  },
  components: {
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    FavoriteMedicalCodeDelegate: () =>
      import("@/components/delegates/FavoriteMedicalCodeDelegate"),
    FavoriteMedicalCodesFilter: () =>
      import("@/components/lists/FavoriteMedicalCodesFilter"),
  },
  methods: {
    async fetchFavorites() {
      this.beforeRequest();
      return MedicalcodesService.getFavorites(this.pagination, this.model)
        .then((reply) => {
          this.favorites = reply.results;
          this.reset();
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.handleError("Coś poszło nie tak.");
        });
    },
    async fetchCategories() {
      this.beforeRequest();
      return MedicalcodesService.getCategories(this.pagination, this.model)
        .then((reply) => {
          this.codes = reply.results;
          this.codes.forEach((element) => {
            this.$set(element, "children", []);
            this.$set(element, "id", element.id);
          });
          this.reset();
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.handleError("Coś poszło nie tak.");
        });
    },
    async fetchChildren(item) {
      this.beforeRequest();
      return MedicalcodesService.getChildren(this.pagination, this.model, item.code)
        .then((reply) => {
          let items = reply.results;
          items.forEach((element) => {
            this.$set(element, "id", element.id)
            if (!element.selectable) {
              this.$set(element, "children", []);
              return element;
            }
          });
          item.children.push(...items);
          this.reset();
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.handleError("Coś poszło nie tak.");
        });
    },
    save() {
      this.beforeRequest();
      MedicalcodesService.updateFavorites(this.model, this.favorites)
        .then((reply) => {
          this.handleSuccess(`Zapisano ulubione kody ${this.humanizedModel}.`);
          this.show = false;
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.handleError(`Nie udało się zapisać ulubionych kodów ${this.humanizedModel}.`);
        });
    },
  },
  mounted() {
    this.fetchFavorites().then(this.fetchCategories());
  },
};
</script>

<style lang="scss" scoped>
.default-style ::v-deep .v-treeview-node__root:hover::before {
  opacity: 0 !important;
}
</style>